import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './dashboard-nav.module.scss';
import dashLogo from '../../images/dash_logo.svg';
import userImage from '../../images/ondo_img.png';
import popoverIcon from '../../images/popover_icon.svg'
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/authActions';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';

const DashboardNav = (props) => {
    const[popover, setPopover] = useState(false);

    DashboardNav.propTypes = {
        auth: PropTypes.object.isRequired
    }

    const { user } = props.auth
    return(
        <div className={styles.nav_container_fluid}>
            <div className={styles.container}>
                <div className={styles.dash_nav_logo}>
                    <Link to ='/'><img src={dashLogo} alt="logo"/></Link>
                </div>
                <div className={styles.user_profile}>
                    <div className={styles.user_avatar}>
                        <Avatar name={`${user?.firstname} ${user?.lastname}`} size="50" round={true} color= "#E76C4B"/>
                    </div>
               
                    {/* <div className={styles.user_image}>
                        <img src={userImage} alt="user image"/>
                    </div> */}
                    <div className={styles.username}>
                        {user ? user?.firstname : ""}
                    </div>
                    <div className={styles.popover_icon}>
                        <img src={popoverIcon} alt="popover icon" onClick={() => setPopover(!popover)}/>
                    </div>
                </div>
            </div>
            {
                popover ? (
                    <div className={styles.popover_menu}>
                        <Link to ="/word-blocks">Word Blocks</Link>
                        <Link to="/" onClick={props.logout}>Log out</Link>
                    </div>
                ): null
            }
        </div>
    )
}


const mapStateToProps = state =>({
    auth:state.auth
})

export default connect(mapStateToProps, { logout })(DashboardNav);

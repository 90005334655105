import React from 'react';
import styles from './faq-section.module.scss'
import faq_illustration from '../../images/faq_ill.svg'
import laugh_girl from '../../images/laugh_girl.png'
import { withRouter } from 'react-router-dom';
import { contributorData, faqData } from './faqData';
import FaqItem from '../FaqItem/FaqItem';
import ContributorItem from '../ContributorItem/ContributorItem';

const FaqSection = ({history}) => {
    return(
        <div className={styles._}>
            <div className={styles.container}>
                <div className={styles.col_one}>
                    <div className={styles.accordion_container}>
                        <div className={styles.accordion_list}>
                            {
                                faqData.map((data) => {
                                    return <FaqItem key={data.id} {...data}/>
                                })
                            }
                        </div>
                    </div>
                    <div className={styles.contributors}>
                        {
                            contributorData.map((contributor) => {
                                return <ContributorItem key={contributor.id} img={contributor.imgUrl} />
                            })
                        }
                    </div>
                </div>
                <div className={styles.col_two}>
                    <div className={styles.faq_illustration}>
                        <img src={faq_illustration} alt="faq illustration"/>
                    </div>
                    <div className={styles.faq_info}>
                        <h4>1000+ Users</h4>
                        <p>Since launching, over 1000 Nigerians have used the VRP to contribute to our voice research, be part of this ground-breaking project.</p>
                        <button onClick={() => history.push('/signup')}>Create your account</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(FaqSection) 
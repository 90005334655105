import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import styles from './password-recovery-form.module.scss';
import { connect } from "react-redux";
import { passwordRecovery } from '../../redux/actions/authActions';
import ReactLoading from 'react-loading';

const PasswordRecoveryForm = (props) => {
    return(
        <div className={styles.recovery_form_container_fluid}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <h1>Password Recovery</h1>
                        <p>Please enter your registered email address</p>
                    </div>
                    <div className={styles.recovery_form}>
                        <Formik
                            initialValues={{ email: ''}}
                            validationSchema={Yup.object({
            
                                email: Yup.string()
                                .email('Invalid email address')
                                .required('Email address is required'),
            
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                props.passwordRecovery(values);
                            }}
                            > 
                            <Form action="">
                                <div className={styles.input_item}>
                                    <label>Email Address</label> <br />
                                    <Field type="email" name="email" placeholder="" className={styles.input} />
                                    <ErrorMessage name="email" className={styles.error_message} component="div" />
                                </div>
                                {
                                    props.isLoading ? ( 
                                    <div className={styles.button_container}>
                                        <button className={styles.loading_button} type="submit" disabled> <p>Submit</p>  <ReactLoading className={styles.loading_icon} type={'spin'} color={'#FFFFFF'} height={'20%'} width={'20%'} /></button>
                                    </div>
                                     ) : 
                                    (<div className={styles.button_container}>
                                        <button type="submit">Submit</button>
                                     </div>)
                                    
                                }
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps, { passwordRecovery }) (PasswordRecoveryForm);
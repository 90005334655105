import React from 'react';

import styles from './terms-hero.module.scss';

const TermsHero = () => {

    return(
        <div className={styles._}>
            <div className={styles.container}>
               <p>Terms of use</p>
            </div>
        </div>
    )
}

export default TermsHero
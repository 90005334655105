import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import {Bounce, Fade, Zoom} from 'react-reveal';

import anambra from '../../images/anambra_img.png';
import ondo from '../../images/ondo_img.png';
import katsina from '../../images/katsina_img.png';
import ogun from '../../images/ogun_img.png';
import mobileAnambra from '../../images/mobile_anambra.svg';
import mobileKaduna from '../../images/mobile_kaduna.svg';
import mobileOndo from '../../images/mobile_ondo.svg';
import mobileOgun from '../../images/mobile_ogun.svg';
import listen from '../../images/listen.png';
import unlock from '../../images/unlock.png';
import speak from '../../images/speak_mic.png'
import styles from './home-info.module.scss';



const HomeInfo = ({history}) => {
    return(
        <div className={styles.home_info_container_fluid}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.info_topic}>
                        <Bounce top>
                            <h1>Be part of the millions of Nigerians contributing to ground-breaking voice research</h1>
                        </Bounce>
                        <Fade bottom>
                            <p>Play your part in boosting inclusiveness in voice-technology. Record as many voice clips as you want, <Link to ='/signup'className={styles.account_link}>create your account</Link> to access full word blocks, record your voice and keep track of all your progress in your dashboard.</p>
                            <a href="#word-block-section"><button onClick={() => history.push('/signup')}>Get Started</button></a>
                        </Fade>
                    </div>
                    <div className={styles.images_container}>
                        <div className={styles.first_set_img}>
                            <Zoom bottom>
                                <div className={styles.anambra_image}>
                                    <img src={anambra} alt=""/>
                                    <div className={styles.anambra_details}>
                                        <p>Anambra State <br/> 24 years old</p> 
                                    </div>
                                </div>
                            </Zoom>
                            <Zoom bottom>
                                <div className={styles.katsina_image}>
                                    <div className={styles.katsina_details}>
                                        <p>Katsina State <br/> 24 years old</p> 
                                    </div>
                                    <img src={katsina} alt=""/>
                                </div>
                            </Zoom>
                            <Zoom bottom>
                                <div className={styles.ondo_image}>
                                    <div className={styles.ondo_details}>
                                        <p>Ondo State <br/> 24 years old</p> 
                                    </div>
                                    <img src={ondo} alt=""/>
                                </div>
                            </Zoom>
                        </div>
                        <Zoom bottom>
                            <div className={styles.second_set_img}>
                                <div className={styles.ogun_image}>
                                    <div className={styles.ogun_details}>
                                        <p>Ogun State <br/> 24 years old</p> 
                                    </div>
                                    <img src={ogun} alt=""/>
                                </div>
                            </div>
                        </Zoom>
                        
                    </div>
                    <div className={styles.mobile_images_container}>
                        <div className={styles.first_set_img}>
                            <Zoom bottom>
                                <div className={styles.anambra_image}>
                                    <img src={mobileAnambra} alt=""/>
                                    <div className={styles.anambra_details}>
                                        <p>Anambra State <br/> 24 years old</p> 
                                    </div>
                                </div>
                            </Zoom>
                            <Zoom bottom>
                                <div className={styles.katsina_image}>
                                    <img src={mobileKaduna} alt=""/>
                                    <div className={styles.katsina_details}>
                                        <p>Katsina State <br/> 24 years old</p> 
                                    </div>
                                </div>
                            </Zoom>
                        </div>
                        <div className={styles.second_set_img}>
                            <Zoom bottom>
                                <div className={styles.ondo_image}>
                                    <img src={mobileOndo} alt=""/>
                                    <div className={styles.ondo_details}>
                                        <p>Ondo State <br/> 24 years old</p> 
                                    </div>
                                </div>
                            </Zoom>
                            <Zoom bottom>
                                <div className={styles.ogun_image}> 
                                    <img src={mobileOgun} alt=""/>
                                    <div className={styles.ogun_details}>
                                        <p>Ogun State <br/> 24 years old</p> 
                                    </div>
                                </div>
                            </Zoom>
                        </div>
                        
                    </div>

                    <div className={styles.unlock_speak_listen}>
                        <Fade>
                            <div className={styles.unlock_img}>
                                <img src={unlock} alt="unlock image"/>
                                <p>Unlock</p>
                            </div>
                        </Fade>
                        <Fade>
                            <div className={styles.speak_img}>
                                <img src={speak} alt="speak image"/>
                                <p>Speak</p>
                            </div>
                        </Fade>
                        <Fade>
                            <div className={styles.listen_img}>
                                <img src={listen} alt="listen image"/>
                                <p>Listen</p>
                            </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(HomeInfo);
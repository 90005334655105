import React, { useState } from 'react';
import styles from './faq-item.module.scss'

const FaqItem = ({question, answer}) => {
    const [showAnswer, setShowAnswer] = useState(false)

    return(
        <div className={styles.accordion_item}>
            <div className={styles.question} onClick={() => setShowAnswer(!showAnswer)}>
                <p>{question}</p>
            </div>
            {
                showAnswer &&  
                <div className={styles.answer}>
                    <p>{answer}</p>
                </div>
            }
        </div>
    )
}

export default FaqItem
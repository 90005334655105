import React from 'react';
import Layout from '../components/Layout';
import TermsHero from '../components/TermsHero/TermsHero';
import TermsSection from '../components/TermsSection/TermsSection';


const TermsPage = () => {
    return(
        <Layout>
           <TermsHero />
           <TermsSection />
        </Layout>
    )
}



export default TermsPage;
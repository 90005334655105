import React, { useEffect, useRef } from 'react';
import Chart from "chart.js";
import styles from './user-graph.module.scss';

Chart.defaults.global.defaultFontFamily = "'Roboto', sans-serif";
Chart.defaults.global.defaultFontColor = "#C4C4C4";
Chart.defaults.global.defaultFontSize = 16;
Chart.defaults.global.legend.display = true;
Chart.defaults.global.elements.line.tension = 0.5;

const UserGraph = ({data, label}) => {

    const chartRef = useRef(null)

    useEffect(() => {
        const userChartRef = chartRef.current.getContext("2d");
        const {height: graphHeight} = userChartRef.canvas;

        let gradientLine = userChartRef
            .createLinearGradient(0, 0, 0, graphHeight);
        gradientLine.addColorStop(0, "rgb(231, 108, 75, 1)");
        gradientLine.addColorStop(0.5, "rgb(231, 108, 75, 1)");
        gradientLine.addColorStop(1, "rgb(231, 108, 75, 0.7)");

        new Chart(userChartRef, {
            type: "line",
            data: {
                labels: label.length === data.length ? label : new Array(data.length).fill("Data"),
                datasets: [
                    {
                        label:"Voice Recordings",
                        data:data,
                        fill: true,
                        borderColor: "rgb(231, 108, 75, 1)",
                        backgroundColor:gradientLine
                    }
                ]
            },
            options:{
                //Customize chart options;
                responsive: true,
                maintainAspectRatio: true,
                // layout: {
                //     padding: {
                //         top: 5,
                //         left: 15,
                //         right: 15,
                //         bottom: 15
                //     }
                // }
                scales: {
                    xAxes: [{
                        // barPercentage: 0.2,
                        ticks: { display: true },
                        gridLines: {
                            display: false,
                            drawBorder: true
                        }
                    }],
                    yAxes: [{
                        ticks: { 
                            display: true,
                            beginAtZero: true 
                        },
                        gridLines: {
                            display: true,
                            drawBorder: false
                        }
                    }]
                },
            }
        });
    }, [data, label])

    return(
        <div className={styles.chart_container_fluid}>
            <div className={styles.chart_container}>
                <p>Your Monthly Stats</p>
                <canvas 
                    id="userChart"
                    ref={chartRef}
                />
            </div>
        </div>
    )
}

export default UserGraph;
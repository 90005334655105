import React, { useEffect, useState } from 'react';
import DashboardNav from '../components/DashboardNav/DashboardNav';
import DashboardProgress from '../components/DashboardProgress/DashboardProgress';
import NewsletterForm from '../components/NewsletterForm/NewsletterForm';
import Footer from '../components/Footer/Footer';
import UserGraph from '../components/UserGraph/UserGraph';
import {managerData, yearLabels} from '../../src/mockData';
import {activities} from '../../src/mockData';
import { connect } from 'react-redux';
import { loadMonthlyStats } from '../redux/actions/authActions';
import dateFormat from 'dateformat';


const DashboardPage = (props) => {

    const { stats } = props.auth
  

    const monthsData = stats.map((activity) => {
        return(
            dateFormat(activity.month, "mmm yy")
        )
    })

    const recordingData = stats.map((activity) => {
        return(
            activity.recordings
        )
    })

    // const[data, setData] = useState(managerData);
    // const[label, setLabel] = useState(yearLabels);

    // const[data, setData] = useState([...recordingData]);
    // const[label, setLabel] = useState([ ...monthsData]);

    useEffect(() => {
        props.loadMonthlyStats()
    }, [])

    return(
        <div>
            <DashboardNav />
            <DashboardProgress />
            <UserGraph data={recordingData} label={monthsData}/>
            <NewsletterForm />
            <Footer />
        </div>
    )
}

const mapStateToProps = (state) => ({
    auth:state.auth
})

export default connect(mapStateToProps, {loadMonthlyStats})(DashboardPage);
import React from 'react';
import { Link } from 'react-router-dom';
import {Slide} from 'react-reveal';

import styles from './home-about.module.scss';
import peopleCard from '../../images/people_card.svg';
import micCard from '../../images/mic_card.svg';
import lockCard from '../../images/lock_card.svg';

const HomeAbout = () => {
    return(
        <div className={styles.home_about_container_fluid}>
            <div className={styles.container}>
                <Slide left>
                    <div className={styles.about_section}>
                        <h1>Why is this so important to us?</h1>
                        <p> VRP was built because we are extremely passionate about creating personalized voice-tech experiences for more Nigerians. How? By using the voice data you help provide, we train AI to recognize Nigerian accents and language.</p>
                        <Link to="/FAQ">Know More</Link>
                        <p> We care deeply about you and your privacy, that means all information provided on this website is safe and secure...</p>
                        <Link to="/privacy-policy">Privacy Policy</Link>
                        {/* <div className={styles.button_container}>
                            <button>Read More</button>
                        </div> */}
                    </div>
                </Slide>
                <Slide right>
                    <div className={styles.images_section}>
                        <div className={styles.people_card}>
                            <img src={peopleCard} alt=""/>
                        </div>
                    <div className={styles.lock_card}>
                            <img src={lockCard} alt=""/>
                    </div>
                    <div className={styles.mic_card}>
                            <img src={micCard} alt=""/>
                    </div>     
                    </div>
                </Slide>
            </div>
        </div>
    )
}

export default HomeAbout;


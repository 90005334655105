const badgesData = [
    {
        id:1,
        name:"Beginner Badge",
        image:"https://i.ibb.co/7nRhW0g/badge-img.png",
        details:"Reached 100 voice recordings",
        limit:100
    },

    {
        id:2,
        name:"Intermediate Badge",
        image:"https://i.ibb.co/7nRhW0g/badge-img.png",
        details:"Reached 1000 voice recordings",
        limit:1000
    },

    {
        id:3,
        name:"Advanced badge",
        image:"https://i.ibb.co/7nRhW0g/badge-img.png",
        details:"Reached 10000 voice recordings",
        limit:10000
    },

    // {
    //     id:4,
    //     name:"Plenipotentiary badge",
    //     image:"https://i.ibb.co/7nRhW0g/badge-img.png",
    //     details:"Reached 15000 voice recordings",
    //     limit:20000
    // },

]

export default badgesData;
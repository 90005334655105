import React from 'react';
import Layout from '../components/Layout';
import RegisterForm from '../components/RegisterForm/RegisterForm';

const RegisterPage = () => {
    return(
        <Layout>
            <RegisterForm />
        </Layout>
    )
}

export default RegisterPage;
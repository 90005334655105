import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { register } from '../../redux/actions/authActions';
import ReactLoading from 'react-loading';
import styles from './register-form.module.scss';
import { state_locals } from "../../utils/state_locals";

const RegisterForm = (props) => {
    const validationSchema = Yup.object({
        firstname: Yup.string()
        .max(50, 'Must not be greater than 50 characters')
        .required('First Name is required'),

        lastname: Yup.string()
        .max(50, 'Must not be greater than 50 characters')
        .required('Last Name is required'),

        email: Yup.string()
        .email('Invalid email address')
        .required('email address is required'),

        gender: Yup.string()
        .required('Gender is required'),

        state: Yup.string()
        .required('State is required'),

        lga: Yup.string()
        .required('Local Gov Area is required'),

        password: Yup.string().required("Password is required"),

        confirm_password: Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm password"),

        terms_agreement: Yup.bool().oneOf([true], 'Accept Terms & Conditions is required')

    });

    const {
        handleSubmit,
        handleChange,
        values,
        errors,
        touched,
        isSubmitting,
      } = useFormik({
        initialValues:{ 
            firstname: '',
            lastname:'', 
            email: '', 
            password: '', 
            confirm_password:'', 
            gender:'', 
            state:'Abia State', 
            lga: 'Aba South', 
            terms_agreement:false 
        },
        validationSchema,
        onSubmit(values) {
            props.register(values);
        },
      });


    RegisterForm.propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated:PropTypes.bool,
        isLoading:PropTypes.bool
    }

    if(props.isAuthenticated){
        return <Redirect to="/dashboard" />
    }

    

    return(
        <div className={styles.register_form_container_fluid}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <h1>Create your account</h1>
                        <Link to="/signin">Sign in instead?</Link>
                    </div>
                    <div className={styles.register_form}>
                        <form onSubmit={handleSubmit}>
                            <div className={styles.form_name}>
                                <div className={styles.input_item}>
                                    <label>First Name</label> <br />
                                    <input type="text" name="firstname" placeholder="John" onChange={handleChange} value={values.firstname} className={styles.input}/>

                                    {errors.firstname && touched.firstname && <p className={styles.error_message}>{errors.firstname}</p>}
                                    
                                </div>
                                <div className={styles.input_item}>
                                    <label>Last Name</label> <br />
                                    <input type="text" name="lastname" placeholder="Doe" onChange={handleChange} value={values.lastname} className={styles.input}/>

                                    {errors.lastname && touched.lastname && <p className={styles.error_message}>{errors.lastname}</p>}
                                    
                                </div>
                            </div>
                            <div className={styles.email_input}>
                                <label>Email Address</label> <br />
                                <input type="email" name="email" placeholder="example@email.com" onChange={handleChange} value={values.email} className={styles.input}/>

                                {errors.email && touched.email && <p className={styles.error_message}>{errors.email}</p>}
                                
                            </div>
                            <div className={styles.option_item}>
                                <label>Gender</label> <br />
                                <select name="gender" onChange={handleChange} id="gender" value={values.gender}className={styles.select}>
                                    <option value="">--Select your gender--</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>

                                {errors.gender && touched.gender && <p className={styles.error_message}>{errors.gender}</p>}
            
                            </div>
                            <div className={styles.option_item}>
                                <label>State of Origin</label> <br />
                                <select name="state" onChange={handleChange} id="state" value={values.state}className={styles.select}>
                                    {Object.keys(state_locals).map((stateKey, index) => {
                                    return (
                                        <option key={index} value={stateKey}>{stateKey}</option>
                                    );
                                    })}
                                </select>

                                {errors.state && touched.state && <p className={styles.error_message}>{errors.state}</p>}

                            </div>
                            <div className={styles.option_item}>
                                <label>Local Government Area</label> <br />
                                <select name="lga" onChange={handleChange} id="lga" value={values.lga} className={styles.select}>
                                    {Object.keys(state_locals[values.state]).map((lgaKey, index) => {
                                    return (
                                        <option key={index} value={lgaKey}>{lgaKey}</option>
                                    );
                                    })}
                                </select>

                                {errors.lga && touched.lga && <p className={styles.error_message}>{errors.lga}</p>}
                            </div>
                            <div className={styles.email_input}>
                                <label>Password</label> <br />
                                <input type="password" name="password" placeholder="" onChange={handleChange} value={values.password} className={styles.input}/>

                                {errors.password && touched.password && <p className={styles.error_message}>{errors.password}</p>}
                            </div>
                            <div className={styles.email_input}>
                                <label>Confirm Password</label> <br />
                                <input type="password" name="confirm_password" placeholder="" onChange={handleChange} value={values.confirm_password} className={styles.input}/>

                                {errors.confirm_password && touched.confirm_password && <p className={styles.error_message}>{errors.confirm_password}</p>}
                            </div>
                            <div className={styles.checkbox_input}>
                                <p><input type="checkbox" name="terms_agreement" checked={values.terms_agreement} onChange={handleChange}/>By checking this box you agree to the terms of use as stated in our <Link>Privacy Policy</Link></p>

                                {errors.terms_agreement && touched.terms_agreement && <p className={styles.error_message}>{errors.terms_agreement}</p>}
                            </div>
                            {
                                props.isLoading ? ( 
                                <div className={styles.button_container}>
                                    <button className={styles.loading_button} type="submit" disabled> <p>Submit</p>  <ReactLoading className={styles.loading_icon} type={'spin'} color={'#FFFFFF'} height={'20%'} width={'20%'} /></button>
                                </div>
                                    ) : 
                                (<div className={styles.button_container}>
                                    <button type="submit">Submit</button>
                                    </div>)
                                
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps, { register })(RegisterForm);
import React from 'react';

import '../styles/styles.scss';
import Nav from './Nav/Nav';
import Footer from './Footer/Footer';

const Layout = (props) => {
    return(
        <div>
            <Nav />
            {props.children}
            <Footer />
        </div>
    )
}

export default Layout;
import React from 'react';
import FaqHero from '../components/FaqHero/FaqHero';
import FaqSection from '../components/FaqSection/FaqSection';
import Layout from '../components/Layout';

const FaqPage = () => {
    return(
        <Layout>
           <FaqHero />
           <FaqSection />
        </Layout>
    )
}



export default FaqPage;
import React, { useState } from 'react';
import Slider from 'react-slick';
import "./badges-slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './badges-modal.module.scss';
import { connect } from 'react-redux'
import badgeIcon from '../../images/badge.svg';
import closeBtn from '../../images/close_btn.svg'
import rightArrow from '../../images/right_arrow.svg';
import badgesData from './badges-data';


function SampleNextArrow(props) {
    
    const { onClick } = props;
    return (
        <div className={styles.arrow_container_right} onClick={onClick}>
           <img src={rightArrow} alt=""/>
        </div>
    );
}
  
function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={styles.arrow_container_left} onClick={onClick}>
           <img src={rightArrow} alt=""/>
        </div>
    );
}

const BadgesModal = (props) => {

    const[closeModal, setCloseModal] = useState(true);
    const [badges, setBadges] = useState(badgesData);
    const {user} = props.auth

    const userRecordings = user.recordings ? user.recordings : 0;

    const settings = {
        infinite: false,
        slidesToShow: 3,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
              }
            },
            {
                breakpoint: 990,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                  initialSlide: 2
                }
              },
            {
              breakpoint: 700,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 2
              }
            },
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return(
      closeModal ?
        (<div className={styles.badges_modal_fluid}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.close_btn}>
                        <img src={closeBtn} alt=""  onClick={() =>{ setCloseModal(false);
                        props.setOpenModal(false)}}/>
                    </div>
                    <div className={styles.header}>
                        All Badges
                    </div>
                    <div className={styles.badge_item_container}>
                        <Slider {...settings}>
                            {
                                badges.map((badge) => {
                                    const { id, name, image, details, limit } = badge
                                    return(
                                        <div className={`${styles.badge_item} ${userRecordings >= limit && styles.unlock_badge_item}`} key={id}>
                                            <div className={styles.badge_image}>
                                                <img src={image} alt="badge icon"/>
                                            </div>
                                            <div className={styles.streak_text}>
                                                <p className={styles.streak_topic}>{name}</p>
                                                <p className={styles.streak_reach}>{details}</p>   
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </Slider>
                    </div>
                </div>
            </div>
        </div>) : null
    )
}

const mapStateToProps = state => ({
    auth:state.auth
})

export default connect(mapStateToProps)(BadgesModal)
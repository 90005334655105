import React, { useState, useEffect } from 'react';
import styles from './recording-word-blocks.module.scss';
import './recording-word-block-tabs.css';
import RecordingWordBlockItem from '../RecordingWordBlockItem/RecordingWordBlockItem';
import { connect } from 'react-redux';
import { loadWordBlock } from '../../redux/actions/authActions';
import ReactLoading from 'react-loading';
import Slider from 'react-slick';
import "./word-block-list-slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from '../../images/right_arrow.svg';

function SampleNextArrow(props) {
    
    const { onClick } = props;
    return (
        <div className={styles.arrow_container_right} onClick={onClick}>
           <img src={rightArrow} alt=""/>
        </div>
    );
}
  
function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={styles.arrow_container_left} onClick={onClick}>
           <img src={rightArrow} alt=""/>
        </div>
    );
}

const RecordingWordBlockList = (props) => {
    const { isLoading } = props.auth
    const { user } = props.auth
    const { wordBlocks } = props.auth

    useEffect(()=>{   
        props.loadWordBlock();
    }, [])

    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };
    
    // const progressData = { bgColor: 'linear-gradient(180deg, #E76C4B 0%, #FFF27B 100%)', completed: user.recordings ? user.recordings : 0 };
    // console.log(wordBlocks)

    if(isLoading){
        return(
            <div  className={styles.loading_icon}>
                <ReactLoading type={'bars'} color={'#E76C4B'} height={'15%'} width={'15%'} />
            </div>
        )
    }

    if(wordBlocks.length === 0){
        return(
            <div className={styles.empty_state}>
                <p>No more available word blocks</p>
            </div>
        )
    }

    return(
        <div className={styles.word_list_container}>
            <Slider {...settings}>
                {
                    wordBlocks.map((item) => (
                        <RecordingWordBlockItem key={item.id} content={item.word}/>
                    ))
                }
            </Slider>
        </div>
    
    )
}

const mapStateToProps = state => ({
    auth:state.auth
})

export default connect(mapStateToProps, {loadWordBlock})(RecordingWordBlockList)
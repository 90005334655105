import React from 'react';
import Layout from '../components/Layout';
import AudioRecordingHero from '../components/AudioRecordingHero/AudioRecordingHero';
import RecordingWordBlockList from '../components/RecordingWordBlockList/RecordingWordBlockList';
import AudioRecordingSubHero from '../components/AudioRecordingSubHero/AudioRecordingSubHero';


const AudioRecordingPage = () => {
    return(
        <Layout>
            <AudioRecordingHero />
            <AudioRecordingSubHero />
            <RecordingWordBlockList />
        </Layout>
    )
}

export default AudioRecordingPage;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames'
import vrpLogo from '../../images/vrp_logo.svg'
import styles from './nav.module.scss';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../redux/actions/authActions';


const Nav = (props) => {
    Nav.propTypes = {
        auth: PropTypes.object.isRequired,
        logout: PropTypes.func.isRequired
    }

    const{ isAuthenticated } = props.auth;

    const [navOpen, setNavOpen] = useState(false)

    const authLinks = (
        <div className={styles.nav_buttons}>
            <Link to ="/" className={styles.nav_link}>
                Home
            </Link>
            <Link to ="/word-blocks" className={styles.nav_link}>
                Word Blocks
            </Link>
            <Link to ="/dashboard" className={styles.signup_button}>
                Dashboard
            </Link>
            <Link className={styles.dashboard_button} onClick={props.logout}>
                Logout
            </Link>
        </div>
    );

    const guestLinks = (
        <div className={styles.nav_buttons}>
            <Link to ="/" className={styles.nav_link}>
                Home
            </Link>
            <Link to ="/" className={styles.nav_link + ' ' + styles.unactive_link}>
                Word Blocks
            </Link>
            <Link to ="/signin" className={styles.signup_button}>
                Sign In
            </Link>
            <Link to ="/signup" className={styles.dashboard_button}>
                Get Started
            </Link>
        </div>
    )


    return(
        <nav>
            <div className={styles.nav_container_fluid}>
                <div className={styles.nav_container}>
                    <div className={styles.logo_img}>
                    <Link to="/"><img src={vrpLogo} alt="logo"/></Link> 
                    </div>
                    {
                        isAuthenticated ? authLinks : guestLinks
                    }
                    <div className={classNames(styles.hamburger_menu, {[styles.toggle_nav] : navOpen === true})} onClick={() => {setNavOpen(!navOpen)}}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>
            <div className={classNames(styles.menu_container, {[styles.close_menu_container] : navOpen === false})}>    
            {/* <div className={classNames(styles.hamburger_menu, {[styles.toggle_nav] : navOpen === true})} onClick={() => {setNavOpen(!navOpen)}}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div> */}
                <div className={styles.menu_items}>
                    {
                        isAuthenticated ? authLinks : guestLinks
                    }
                </div>
            </div>
        </nav>
    )
}

const mapStateToProps = state => ({
    auth:state.auth
})

export default connect(mapStateToProps, { logout }) (Nav);
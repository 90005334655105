import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import { connect } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { passwordReset } from '../../redux/actions/authActions';
import styles from './password-reset-form.module.scss';

const PasswordResetForm = (props) => {
    let { token } = useParams();
    console.log(token)

    if(props.redirect){
        return <Redirect to ="/signin" />
    }
    return(
        <div className={styles.reset_form_container_fluid}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <h1>Password Reset</h1>
                        <p>Create your new password</p>
                    </div>
                    <div className={styles.reset_form}>
                        <Formik
                            initialValues={{ password: ''}}
                            validationSchema={Yup.object({
            
                                password: Yup.string()
                                .required('Password is required'),
            
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                props.passwordReset(values, token);
                            }}
                            >   
                            <Form action="">
                                <div className={styles.input_item}>
                                    <label>Password</label> <br />
                                    <Field type="password" name="password" placeholder="" className={styles.input} />
                                    <ErrorMessage name="password" className={styles.error_message} component="div" />
                                </div>
                                {
                                    props.isLoading ? ( 
                                    <div className={styles.button_container}>
                                        <button className={styles.loading_button} type="submit" disabled> <p>Create</p>  <ReactLoading className={styles.loading_icon} type={'spin'} color={'#FFFFFF'} height={'20%'} width={'20%'} /></button>
                                    </div>
                                     ) : 
                                    (<div className={styles.button_container}>
                                        <button type="submit">Create</button>
                                     </div>)
                                    
                                } 
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isLoading: state.auth.isLoading,
    redirect: state.auth.redirect
})

export default connect(mapStateToProps, { passwordReset }) (PasswordResetForm);
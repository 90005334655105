import React, { useState, useEffect } from 'react';
import recordMic from '../../images/record_mic.svg'
import saveIcon from '../../images/save_icon.svg';
import MicRecorder from 'mic-recorder-to-mp3';
import stopButton from '../../images/stop_button.svg';
import styles from './word-block-list.module.scss';
import { saveAudio } from '../../redux/actions/authActions';
import { connect } from "react-redux";
import ReactLoading from 'react-loading';
import wave_img from '../../images/waves_lg.svg'

const Mp3Recorder = new MicRecorder({ bitRate: 128 });


const RecordingWordBlockItem = (props) => {

 

    const[isRecording, setIsRecording] = useState(false);
    const[blobURL, setBlobURL] = useState('');
    const[isBlocked, setIsBlocked] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [audioFile, setAudioFile] = useState();
    const { isLoading } = props.auth;

    // console.log(audioFile)
    const start = () => {
        if (isBlocked) {
          console.log('Permission Denied');
        } else {
          Mp3Recorder
            .start()
            .then(() => {
              setIsRecording(true);
            }).catch((e) => console.error(e));
        }
      };

    const stop = () => {
    Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)

          setBlobURL(blobURL)
          const file = new File(buffer, 'music.mp3', {
            type: blob.type,
            lastModified: Date.now()
          });
          const formData = new FormData();
          formData.append("audio", file);
          formData.append("word", props.content);
          setAudioFile(formData)
          setIsRecording(false);
        }).catch((e) => console.log(e));

    setShowSave(true);

    };

    const handleClick = () => {
      props.saveAudio(audioFile)
    }

    useEffect(() => {
        navigator.getUserMedia({ audio: true },
            () => {
              console.log('Permission Granted');
              setIsBlocked(false);
            },
            () => {
              console.log('Permission Denied');
              setIsBlocked(true);
            },
          );
      });

    return(
        <>   
          <div className={styles.word_block_item}>
              <div className={styles.content}>
                  <p className={styles.word_para}>{props.content}</p> 
                  <div className={styles.record_button_container}>
                    {
                      !isRecording ? <img src={recordMic} alt="" onClick={start}/> : <img src={stopButton} alt="stop button" onClick={stop}/>
                    }
                      
                      <p hidden={!isRecording}>Recording...</p>
                  </div>
                  <div className={styles.audio_container}>
                    {
                      showSave && <audio src={blobURL} controls="controls" />
                    }
                      {
                        isLoading ? (
                          <div className={styles.save_audio}>
                            <ReactLoading type={'spokes'} color={'#E76C4B'} height={'15%'} width={'15%'} />
                          </div>
                        ):(
                          <div className={styles.save_audio}>
                            { showSave && <img src={saveIcon} alt="" onClick={handleClick}/> }
                          </div>
                        )
                      }
                      
                  </div>
              </div>
          </div> 
        </>
    )
}

const mapStateToProps = (state) =>({
  auth: state.auth
})

export default connect(mapStateToProps, {saveAudio}) (RecordingWordBlockItem);
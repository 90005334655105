export const faqData = [
    {   
        id:1,
        question: "What is VRP",
        answer: "VRP is an abbreviation for “Voice Recognition Project”. It is a platform designed to collect voice data from Africans towards the research and development of a speech recognition engine for Africans."
    },
    {
        id:2,
        question: "Is it free to use the website?",
        answer: "Yes, it is absolutely free to use the platform. All you need to do is sign up and start donating your voice to advance our research."
    },
    {
        id:3,
        question: "Who should use this platform?",
        answer: "This platform is designed for Africans and as such, only Africans should use this platform."
    },
    {
        id:4,
        question: "Will you make my information public?",
        answer: "We will not make your email public. Also, your submitted demographic data (e.g. gender, lga) will never be made public. However, individual audio clips may be associated with some of your demographic data to facilitate better research."
    },
    {
        id:5,
        question: "How do you ensure the anonymity and privacy of my donated voice?",
        answer: "All your donated voice clips in the dataset are scrubbed of your personal information like name and email before being exported for download and subsequently used in our research."
    },
    {
        id:6,
        question: "Why is it important?",
        answer: "We have discovered that there are some elements of Non-African bias to most speech engines out there. That is why we have embarked on this endeavor to create a speech engine that simply works for Africans."
    },
]

export const contributorData = [
    {
        id:1,
        imgUrl: "https://images.unsplash.com/photo-1531727991582-cfd25ce79613?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MTl8fGJsYWNrJTIwZmFjZXMlMjBwb3J0cmFpdHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
    },
    {
        id:2,
        imgUrl: "https://images.pexels.com/photos/5703006/pexels-photo-5703006.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
    },
    {
        id:3,
        imgUrl: "https://images.unsplash.com/photo-1595419490345-3d2da2916c82?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NTh8fGJsYWNrJTIwZmFjZXMlMjBwb3J0cmFpdHxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
    },
    {
        id:4,
        imgUrl: "https://images.pexels.com/photos/3746226/pexels-photo-3746226.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
    },
    {
        id:5,
        imgUrl: "https://images.unsplash.com/photo-1587064712555-6e206484699b?ixid=MXwxMjA3fDB8MHxzZWFyY2h8MzB8fGJsYWNrJTIwbWFuJTIwZmFjZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
    },
    {
        id:6,
        imgUrl: "https://images.unsplash.com/photo-1593130327710-6657ee327e96?ixid=MXwxMjA3fDB8MHxzZWFyY2h8OTd8fGJsYWNrJTIwZmFjZXxlbnwwfHwwfA%3D%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
    },
    {
        id:7,
        imgUrl: "https://images.pexels.com/photos/3189024/pexels-photo-3189024.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
    },
    {
        id:8,
        imgUrl: "https://images.unsplash.com/photo-1518809595274-1471d16319b7?ixid=MXwxMjA3fDB8MHxzZWFyY2h8NHx8YmxhY2slMjBmYWNlfGVufDB8fDB8&ixlib=rb-1.2.1&auto=format&fit=crop&w=600&q=60"
    }
]
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import HomePage from './pages/home';
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import PasswordRecoveryPage from './pages/password-recovery';
import PasswordResetPage from './pages/password-reset';
import DashboardPage from './pages/dashboard';
import PrivateRoute from './Routes/PrivateRoute';
import { loadUser } from './redux/actions/authActions';
import store from './redux/store';
import AudioRecordingPage from './pages/audio-recording';
import FaqPage from './pages/faq';
import PrivacyPolicyPage from './pages/privacy-policy';
import TermsPage from './pages/terms';




class App extends React.Component {

  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={HomePage} />
          <Route exact path='/signup' component={RegisterPage} />
          <Route exact path='/signin' component={LoginPage} />
          <Route exact path='/password-recovery' component={PasswordRecoveryPage} />
          <Route exact path='/password-reset/:token' component={PasswordResetPage} />
          <Route exact path='/FAQ' component={FaqPage} />
          <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />
          <Route exact path='/terms-of-use' component={TermsPage} />
          <PrivateRoute exact path='/dashboard' component={DashboardPage} />
          <PrivateRoute exact path='/word-blocks' component={AudioRecordingPage} />

        </Switch>
      </div>
    );
  }
}

export default App;

import React from 'react';
import Layout from '../components/Layout';
import HomeHero from '../components/HomeHero/HomeHero';
import HomeInfo from '../components/HomeInfo/HomeInfo';
import HomeAbout from '../components/HomeAbout/HomeAbout';
import NewsletterForm from '../components/NewsletterForm/NewsletterForm';
import { connect } from 'react-redux';
import HomePoints from '../components/HomePoints/HomePoints';
import GuestWordBlockList from '../components/GuestWordBlockList/GuestWordBlockList';



const HomePage = (props) => {
    return(
        <Layout>
            {
                props.isAuthenticated ? <HomeHero /> : null
            }
           <HomeInfo />
           <HomePoints />
           {/* <GuestWordBlockList /> */}
           <HomeAbout />
           <NewsletterForm />
        </Layout>
    )
}

const mapStateToProps = (state) => ({
    isAuthenticated:state.auth.isAuthenticated
})

export default connect(mapStateToProps) (HomePage);
import React from 'react';
import {Fade} from 'react-reveal';
import styles from './home-point.module.scss'
import unlockIcon from '../../images/unlock_icon.svg';
import talkIcon from '../../images/talk_icon.svg'
import playIcon from '../../images/play_icon.svg';
import communityIcon from '../../images/community_icon.svg'


const HomePoints = () => {
    return(
        <div className={styles._}>
            <div className={styles.container}>
                <Fade top>
                    <div className={styles.content}>
                        <h3>Play your part in boosting inclusiveness in voice-technology</h3>
                        <div className={styles.content_item}>
                            <div className={styles.icon}>
                                <img src={unlockIcon} alt="unlock icon"/>
                            </div>
                            <div className={styles.text}>
                                <h4>Unlock</h4>
                                <p>Unlock your voice to help improve voice-tech experiences for more Nigerians. </p>
                            </div>
                        </div>
                        <div className={styles.content_item}>
                            <div className={styles.icon}>
                                <img src={talkIcon} alt="talk icon"/>
                            </div>
                            <div className={styles.text}>
                                <h4>Speak</h4>
                                <p>Donate your voice and keep track of all your progress.</p>
                            </div>
                        </div>
                        <div className={styles.content_item}>
                            <div className={styles.icon}>
                                <img src={playIcon} alt="play icon"/>
                            </div>
                            <div className={styles.text}>
                                <h4>Listen</h4>
                                <p>Validate your voices in your indigenous languages.</p>
                            </div>
                        </div>
                        <div className={styles.content_item}>
                            <div className={styles.icon}>
                                <img src={communityIcon} alt="community icon"/>
                            </div>
                            <div className={styles.text}>
                                <h4>Community</h4>
                                <p>Form a community of different languages.</p>
                            </div>
                        </div>
                    </div>
                </Fade>
            </div>
            <div className={styles.wave_images}>

            </div>
        </div>
    )
}

export default HomePoints
import React from 'react';
import Layout from '../components/Layout';
import LoginForm from '../components/LoginForm/LoginForm';

const LoginPage = () => {
    return(
        <Layout>
            <LoginForm />
        </Layout>
    )
}

export default LoginPage;
import React from 'react';
import styles from './privacy-policy-section.module.scss'

const PrivacyPolicySection = () => {
    return(
        <div className={styles._}>
            <div className={styles.container}>
                <p>Welcome to our Voice Recognition Project (VRP) crowdsourcing app, an online platform to collect the nigerian voice. Our Privacy Policy describes our policies and procedures on the collection, use and disclosure of your information when you use the service and tells you about your privacy rights and how the law protects you. This Privacy Policy applies to the general public (individually referred to as “you” ) of our website to which this Privacy Policy is posted. <br/>
                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy. </p>
                <h3>APPLICATION OF THIS PRIVACY POLICY</h3>
                <p>This Privacy Policy applies to your use of (regardless of means of access) our Platforms. You may access or use our Platforms through a desktop, laptop, mobile phone, tablet, or other consumer electronic devices (each, a "device" ).</p>
                <h3>INFORMATION WE COLLECT</h3>
                <p>To use the core functionality of our website, you will be required to record some words with your voice, then save your recorded audio to the database</p>
                <h3>USE OF THE INFORMATION WE COLLECT</h3>
                <p>To perform the core functionality: Recorded voices can be listened to and then saved by the user for further usage.<br />
                To maintain and improve our service: The recorded voices uploaded will be further used to train the algorithm for the voice recognition research and development project</p>
                <h3>RETENTION OF YOUR PERSONAL DATA</h3>
                <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies. <br/>
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
                <h3>TRANSFER OF YOUR PERSONAL DATA</h3>
                <p>Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer. <br />
                The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
                <h3>DISCLOSURE OF YOUR PERSONAL DATA</h3>
                <h4>Business Transactions</h4>
                <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
                <h4>Law enforcement</h4>
                <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h4>Other legal requirements</h4>
                <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul>
                    <li>Comply with a legal obligation</li>
                    <li>Protect and defend the rights or property of the Company</li>
                    <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>Protect the personal safety of users of the Service or the public</li>
                    <li>Protect against legal liability</li>
                    <li>Security of Your Personal Data</li>
                </ul>
                <p>The security of your personal data is important to us, but remember that no method of transmission over the internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
                <h3>LINK TO OTHER WEBSITES</h3>
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit. <br />
                We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                <h3>CHANGES TO THIS PRIVACY POLICY</h3>
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. <br />
                We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "last updated" date at the top of this Privacy Policy. <br />
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                <h3>CONTACT US</h3>
                <p>If you have any questions about this Privacy Policy, You can contact us:
                By visiting this page on our website: <a href="https://www.iqubesolutions.com.ng/contact" target="_blank">https://www.iqubesolutions.com.ng/contact</a></p>
            </div>
        </div>
    )
}

export default PrivacyPolicySection
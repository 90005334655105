import React from 'react';

import styles from './audio-recording-hero.module.scss';

const AudioRecordingHero = () => {

    return(
        <div className={styles.recording_hero_container_fluid}>
            <div className={styles.container}>
               <p>WORD BLOCK COLLECTIONS</p>
            </div>
        </div>
    )
}

export default AudioRecordingHero
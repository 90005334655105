import React, { useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import styles from './dashboard-progress.module.scss';
import messageIcon from '../../images/message_icon.svg';
import emptyBadgeIcon from '../../images/empty-badge.png'
import ProgressBar from '../ProgressBar/ProgressBar'
import BadgesModal from '../BadgesModal/BadgesModal';
import { connect } from 'react-redux';
import badgesData from '../BadgesModal/badges-data';


const DashboardProgress = (props) => {
    const {user} = props.auth

    const [openModal, setOpenModal] = useState(false);
    const [currentBadge, setCurrentBadge] = useState(badgesData);

    const userRecordings = user?.recordings ? user?.recordings : 0;
    const progressData = { bgColor: 'linear-gradient(180deg, #E76C4B 0%, #FFF27B 100%)', completed: user?.recordings ? user?.recordings : 0 }

    const beginnerLimit = 100
    const intermediateLimit = 1000
    const advancedLimit = 10000;

    const getUserProgress = () => {
        if(userRecordings <= beginnerLimit){
            const percentageProgress = (userRecordings/beginnerLimit)*100
            return Math.round(percentageProgress)
        } else if(userRecordings <= intermediateLimit && userRecordings > beginnerLimit){
            const percentageProgress = (userRecordings/intermediateLimit)*100
            return Math.round(percentageProgress)
        }else{
            const percentageProgress = (userRecordings/advancedLimit)*100
            return Math.round(percentageProgress)
        }
    }

    const getUserBadge = () => {
        if(userRecordings >= beginnerLimit && userRecordings < intermediateLimit){
            return(
                <>
                    <div className={styles.heading}>
                        <p>Badges</p>
                        <button onClick={() => setOpenModal(true)}>View All</button>
                    </div>
                    <div className={styles.badge_image}>
                        <img src={currentBadge[0].image} alt="badge icon"/>
                    </div>
                    <div className={styles.streak_text}>
                        <p className={styles.streak_topic}>{currentBadge[0].name}</p>
                        <p className={styles.streak_reach}>{currentBadge[0].details}</p>   
                    </div>
                </>
            )
        }else if(userRecordings >= intermediateLimit && userRecordings < advancedLimit){
            return(
                <>
                    <div className={styles.heading}>
                        <p>Badges</p>
                        <button onClick={() => setOpenModal(true)}>View All</button>
                    </div>
                    <div className={styles.badge_image}>
                        <img src={currentBadge[1].image} alt="badge icon"/>
                    </div>
                    <div className={styles.streak_text}>
                        <p className={styles.streak_topic}>{currentBadge[1].name}</p>
                        <p className={styles.streak_reach}>{currentBadge[1].details}</p>   
                    </div>
                </>
            )
        }else if(userRecordings >= advancedLimit){
            return(
                <>
                    <div className={styles.heading}>
                        <p>Badges</p>
                        <button onClick={() => setOpenModal(true)}>View All</button>
                    </div>
                    <div className={styles.badge_image}>
                        <img src={currentBadge[2].image} alt="badge icon"/>
                    </div>
                    <div className={styles.streak_text}>
                        <p className={styles.streak_topic}>{currentBadge[2].name}</p>
                        <p className={styles.streak_reach}>{currentBadge[2].details}</p>   
                    </div>
                </>
            )
        }else if(userRecordings < beginnerLimit){
            return(
                <>
                    <div className={styles.heading}>
                        <p>Badges</p>
                        <button onClick={() => setOpenModal(true)}>View All</button>
                    </div>
                    <div className={styles.badge_image}>
                        <img src={emptyBadgeIcon} alt="badge icon"/>
                    </div>
                    <div className={styles.streak_text}>
                        <p className={styles.streak_reach} style={{paddingTop: "13px"}}>Reach the 100 recording milestone to earn your first badge</p>  
                    </div>
                </>
            )
        }else{
            return(
                <>
                    <div className={styles.heading}>
                        <p>Badges</p>
                        <button onClick={() => setOpenModal(true)}>View All</button>
                    </div>
                    <div className={styles.badge_image}>
                        <img src={currentBadge[2].image} alt="badge icon"/>
                    </div>
                    <div className={styles.streak_text}>
                        <p className={styles.streak_reach} style={{paddingTop: "13px"}}>You exceed our recording expectation. Congratulations</p>  
                    </div>
                </>
            )
        }
    }

    // {user.recordings ? 100 - user.recordings : 100}

    return(
        <div className={styles.dash_progress_fluid}>
            <div className={styles.container}>
                <div className={styles.header}>
                    <p>Your Dashboard</p>
                    <img src={messageIcon} alt="message icon"/>
                </div>
                <div className={styles.progress_counter_container}>
                    <div className={styles.progress_container}>
                        <div className={styles.progress_text}>
                            <p className={styles.name}>Hi {user?.firstname},</p>
                            <p className={styles.info}>You are currently {100 - getUserProgress()}% away from completing your current streak and gaining a new badge.</p>
                        </div>
                        <div className={styles.progress_circular}>
                            {/* <img src={circleProgress} alt=""/> */}
                            <CircularProgressbar 
                                value={getUserProgress()} 
                                text={`${getUserProgress()}%`} 
                                className={styles.circular_progress_bar} 
                                styles={buildStyles({
                                    rotation: 1,
                                    strokeLinecap: 'round',
                                    textSize: '28px',
                                    pathTransitionDuration: 0.5,
                                    // Colors
                                    pathColor: `rgba(255, 242, 123, 1)`,
                                    textColor: '#fff',
                                    trailColor: '#1B2335',
                                    backgroundColor: 'transparent',
                                  })}/>;
                        </div>
                    </div>
                    <div className={styles.counter_container}>
                        <p className={styles.activity_counter}>Activity Counter</p>
                        <p className={styles.digital_counter}>{user?.recordings ? user?.recordings : 0}</p>
                        <small>voice clips recorded </small>
                    </div>
                </div>
                <div className={styles.progress_badges_container}>
                    <div className={styles.current_progress_container}>
                        <div className={styles.heading}>
                            <p>Your current progress</p>
                            <div className={styles.button_container}>
                                <Link to="/word-blocks"><button>Continue</button></Link>
                            </div>
                        </div>
                        <div className={styles.point_level}>
                            <div className={styles.points}>
                                {user?.recordings ? user?.recordings : 0} points
                            </div>
                            {   
                                user?.recordings > intermediateLimit && user?.recordings <= advancedLimit ?
                                <div className={styles.level}>Advanced</div> : 
                                user?.recordings > beginnerLimit && user?.recordings <= intermediateLimit? 
                                <div className={styles.level}>Intermediate</div> : 
                                <div className={styles.level}>Beginner</div>
                            }
                            
                        </div>
                        <div className={styles.progress_bar}>
                            <ProgressBar bgColor={progressData.bgColor} completed={getUserProgress()}/>
                        </div>
                        <div className={styles.progress_content}>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc varius lacus, mi pretium sed lobortis. Egestas a rutrum turpis lacus, dignissim quis tortor, facilisis.</p>
                        </div>
                    </div>
                    <div className={styles.badges_container}>
                        {   
                            getUserBadge()
                            // userRecordings >= beginnerLimit ? <>
                            //      <div className={styles.heading}>
                            //         <p>Badges</p>
                            //         <button onClick={() => setOpenModal(true)}>View All</button>
                            //     </div>
                            //     <div className={styles.badge_image}>
                            //         <img src={currentBadge[0].image} alt="badge icon"/>
                            //     </div>
                            //     <div className={styles.streak_text}>
                            //         <p className={styles.streak_topic}>{currentBadge[0].name}</p>
                            //         <p className={styles.streak_reach}>{currentBadge[0].details}</p>   
                            //     </div>
                            // </>  :  <>
                            //      <div className={styles.heading}>
                            //         <p>Badges</p>
                            //         <button onClick={() => setOpenModal(true)}>View All</button>
                            //     </div>
                            //     <div className={styles.streak_text}>
                            //         <p className={styles.streak_topic}>Reach the 100 milestone to earn your first badge</p> 
                            //     </div>
                            // </> 
                        }
                       
                    </div>
                </div>
            </div>
            {openModal ? <BadgesModal setOpenModal={setOpenModal}/> : null}
        </div>
    )
}

const mapStateToProps = state => ({
    auth:state.auth
})

export default connect(mapStateToProps)(DashboardProgress);
import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOADING_START,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    SAVE_AUDIO_SUCCESS,
    SAVE_AUDIO_FAIL,
    WORD_BLOCKS_LOADED,
    STATS_LOADED,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAIL,
} from '../actions/actionTypes'

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: localStorage.getItem('token')&&true,
    isLoading:false,
    user:null,
    wordBlocks:[],
    stats:[],
    redirect:false
}

export default function(state = initialState, action){
    switch(action.type){
        case USER_LOADING:
        case LOADING_START:
            return {
                ...state,
                isLoading: true
            }
        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
               ...action.payload
            };
        case LOGIN_SUCCESS:
        case REGISTER_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                isLoading:false,
            }
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGOUT_SUCCESS:
        case REGISTER_FAIL:
            localStorage.removeItem('token');
            return {
                ...state,
                token:null,
                user:null,
                isAuthenticated: false,
                isLoading: false
            }
        case FORGOT_PASSWORD_SUCCESS:
        case FORGOT_PASSWORD_FAIL:
        case RESET_PASSWORD_FAIL:
        case SAVE_AUDIO_SUCCESS:
        case SAVE_AUDIO_FAIL:
        case SUBSCRIPTION_SUCCESS:
        case SUBSCRIPTION_FAIL:
            return {
                ...state,
                isLoading: false
            }
        case WORD_BLOCKS_LOADED:
            return {
                ...state,
                isLoading: false,
                wordBlocks:action.payload.wordblocks
            }
        case STATS_LOADED:
            return {
                ...state,
                isLoading: false,
                stats:action.payload.activity
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                redirect: true
            }
        default:
            return state;
    }
}
import axios from 'axios'; 


import {
    USER_LOADING,
    USER_LOADED,
    AUTH_ERROR,
    LOADING_START,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    SAVE_AUDIO_SUCCESS,
    SAVE_AUDIO_FAIL,
    WORD_BLOCKS_LOADED,
    STATS_LOADED,
    SUBSCRIPTION_SUCCESS,
    SUBSCRIPTION_FAIL
} from '../actions/actionTypes'
import { returnSuccess, returnError } from './alertActions';

export const loadingStart = () => ({
    type:LOADING_START
})


//Check Token & Load User

export const loadUser = () => (dispatch, getState) => {
    // User Loading
    dispatch({ type: USER_LOADING});

    //Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    //If token, add to headers config
    if(token){
        config.headers['Authorization'] = `${token}`;
    }

    axios.get('https://speechapi.iqubesolutions.com.ng/api/user', config)
        .then(res => {
            dispatch({
                type: USER_LOADED,
                payload:res.data
            });
            dispatch(loadMonthlyStats()) // load users monthly stats after every render and refresh
            console.log(res.data)
        }).catch(err => {
            // dispatch(returnErrors(err.response.data, err.response.status));
            dispatch({
                type:AUTH_ERROR 
            })
            console.log(err.response.data);
        })
}



//Login User

export const login = (email, password) => (dispatch) => {
    dispatch(loadingStart())

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    // Request Body
    const body = JSON.stringify({email, password})


    axios.post('https://speechapi.iqubesolutions.com.ng/api/user/login', body, config)
        
        .then(res => {
            dispatch({
                type: LOGIN_SUCCESS,
                payload:res.data
            });
            dispatch(returnSuccess("Logged In Successfully"))
        }).catch(err => {
            dispatch({
                type:LOGIN_FAIL
            })
            dispatch(returnError(err.response.data.error, err.response.status));
            console.log(err.response.data);
        })
}


//Register User
export const register = ({firstname, lastname, email, gender, state, lga, password, confirm_password, terms_agreement}) => (dispatch) => {
    dispatch(loadingStart())

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    // Request Body
    const body = JSON.stringify({firstname, lastname, email, gender, state, lga, password, confirm_password, terms_agreement})


    axios.post('https://speechapi.iqubesolutions.com.ng/api/user/register', body, config)
        
        .then(res => {
            dispatch({
                type: REGISTER_SUCCESS,
                payload:res.data
            });
            dispatch(returnSuccess("Account registered successfully"))

        }).catch(err => {
            dispatch({
                type:REGISTER_FAIL
            })
            dispatch(returnError(err.response.data.error, err.response.status));
            console.log(err.response.data); 
        })
}



//Logout user
export const logout = () => (dispatch, getState) => {

    //Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    //If token, add to headers config
    if(token){
        config.headers['Authorization'] = `${token}`;
    }

    axios.get('https://speechapi.iqubesolutions.com.ng/api/user/logout',  config)
        .then(res => {
            dispatch({
                type: LOGOUT_SUCCESS,
            });
            dispatch(returnSuccess(res.data.user))
        }).catch(err => { 
            dispatch(returnError(err.response.data, err.response.status));
        })
}


//User Password Recovery

export const passwordRecovery = ({email}) => (dispatch) => {
    dispatch(loadingStart())

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    // Request Body
    const body = JSON.stringify({email})


    axios.post('https://speechapi.iqubesolutions.com.ng/api/user/forgot-password', body, config)
        
        .then(res => {
            dispatch({
                type:FORGOT_PASSWORD_SUCCESS
            })
            
            dispatch(returnSuccess(res.data.success))
        }).catch(err => {
            dispatch({
                type:FORGOT_PASSWORD_FAIL
            })
            dispatch(returnError(err.response.data, err.response.status));
            console.log(err.response.data);
            
        })
}

//User Password Reset

export const passwordReset = ({password}, token) => (dispatch) => {
    dispatch(loadingStart())


    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    //If token, add to headers config
    if(token){
        config.headers['token'] = `${token}`;
        console.log(token)
    }

    // Request Body
    const body = JSON.stringify({password})


    axios.post('https://speechapi.iqubesolutions.com.ng/api/user/reset-password/', body, config)
        
        .then(res => {
            dispatch({
                type:RESET_PASSWORD_SUCCESS
            })
            
            dispatch(returnSuccess(res.data.success))
           
        }).catch(err => {
            dispatch({
                type:RESET_PASSWORD_FAIL
            })
            dispatch(returnError(err.response.data.error, err.response.status));
            console.log(err.response.data);
            
        })
}


//Load word blocks 
export const loadWordBlock = () => (dispatch, getState) => {
    dispatch(loadingStart())

    //Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    //If token, add to headers config
    if(token){
        config.headers['Authorization'] = `${token}`;
    }

    axios.get('https://speechapi.iqubesolutions.com.ng/api/user/wordblocks', config)
        .then(res => {
            dispatch({
                type: WORD_BLOCKS_LOADED,
                payload:res.data
            });
            console.log(res.data)
        }).catch(err => {
            dispatch(returnError(err.response.data, err.response.status));
            console.log(err.response.data);
            // dispatch({
            //     type:SAVE_AUDIO_FAIL
            // })
        })
}

// Save Users Audio recordings
export const saveAudio = (formData) => (dispatch, getState) => {
    dispatch(loadingStart())

    //Get token from state
    const token = getState().auth.token;
    
    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    //If token, add to headers config
    if(token){
        config.headers['Authorization'] = `${token}`;
    }

    // Request Body
    const body = formData


    axios.post('https://speechapi.iqubesolutions.com.ng/api/user/audio', body, config)
        
        .then(res => {
            dispatch({
                type: SAVE_AUDIO_SUCCESS,
            });
            dispatch(returnSuccess(res.data.success))
            dispatch(loadWordBlock()); //Load new set of filtered word blocks
            dispatch(loadUser()) // updates user's details (for the dashboard) after saving an audio
        }).catch(err => {
            dispatch({
                type:SAVE_AUDIO_FAIL
            })
            dispatch(returnError(err.response.data, err.response.status));
            console.log(err.response.data);
        })
}




//Load User Monthly stats
export const loadMonthlyStats = () => (dispatch, getState) => {
    // dispatch(loadingStart())

    //Get token from state
    const token = getState().auth.token;

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    //If token, add to headers config
    if(token){
        config.headers['Authorization'] = `${token}`;
    }

    axios.get('https://speechapi.iqubesolutions.com.ng/api/user/activity', config)
        .then(res => {
            dispatch({
                type: STATS_LOADED,
                payload:res.data
            });
            // console.log(res.data)
        }).catch(err => {
            dispatch(returnError(err.response.data, err.response.status));
            console.log(err.response.data);
        })
}


//Newsletter Subscription
export const newsletterSubscription = (email) => (dispatch) => {
    dispatch(loadingStart())

    // Headers
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    // Request Body
    const body = JSON.stringify({ email })


    axios.post('https://speechapi.iqubesolutions.com.ng/api/newsletter/subscribe', body, config)
        
        .then(res => {
            dispatch({
                type: SUBSCRIPTION_SUCCESS,
                payload:res.data
            });
            dispatch(returnSuccess("Subscribed successfully to newsletter"))
        }).catch(err => {
            dispatch({
                type:SUBSCRIPTION_FAIL
            })
            dispatch(returnError(err.response.data.message, err.response.status));
            console.log(err.response.data.message);
        })
}

// Setup Private Route Action

export const unauthorizedAccess = () => (dispatch) => {
    dispatch(returnError("You need to sign in to access this page"));
}

// Setup Config with token -helper function - PS: not in use for now
export const tokenConfig = getState => {
     //Get token from state
     const token = getState().auth.token;

     // Headers
     const config = {
         headers: {
             'Content-Type': 'application/json'
         }
     }
 
     //If token, add to headers config
     if(token){
         config.headers['Authorization'] = `${token}`;
     }

     return config;
}
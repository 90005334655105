import React from 'react';
import { Link } from 'react-router-dom';

import styles from './footer.module.scss';
import footerLogo from '../../images/logo_footer.svg';
import facebookIcon from '../../images/facebook_icon.svg';
import twitterIcon from '../../images/twitter_icon.svg';


const Footer = () => {
    const d = new Date()
    const year = d.getFullYear()

    return(
        <div className={styles.footer_container_fluid}>
            <div className={styles.container}>
                <div className={styles.footer_logo}>
                    <img src={footerLogo} alt=""/>
                </div>
                <div className={styles.quick_links}>
                    <div className={styles.quick_links_para}>
                        <Link to='/privacy-policy'>Privacy Policy</Link>
                        <Link to="/terms-of-use">Terms of Use</Link>
                        <Link to="/FAQ">FAQ’S</Link>
                    </div>
                    <div className={styles.social_media_links}>
                        <img src={facebookIcon} alt="facebook icon"/>
                        <img src={twitterIcon} alt="twitter icon"/>
                    </div>
                </div>
                <div className={styles.copyright}>
                    <p>&copy;Copyright iQubeLabs {year}</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;
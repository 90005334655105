import React from 'react';

const ProgressBar = (props) => {
    const {bgColor, completed} = props;

    const containerStyles ={
        height:16,
        backgroundColor:"#E5E5E5",
        borderRadius: '16px',
        boxSizing: 'border-box',
        border:'1px solid transparent'
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        background: bgColor,
        borderRadius:'inherit',
        textAlign:'right',
        fontSize:'14px',
        transition: 'width 1s ease-in-out'
    }

    const labelStyles = {
        padding:5,
        color:'#FFFFFF',
        fontWeight: 'bold'
    }
    return(
        <div style={containerStyles}>
           <div style={fillerStyles}>
               <span style={labelStyles}></span>
           </div>
        </div>
    )
}

export default ProgressBar;
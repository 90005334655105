import React from 'react';
import Layout from '../components/Layout';
import PasswordResetForm from '../components/PasswordResetForm/PasswordResetForm';

const PasswordResetPage = () => {
    return(
        <Layout>
            <PasswordResetForm />
        </Layout>
    )
}

export default PasswordResetPage;
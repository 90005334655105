import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from './recording-modal.module.scss';
import stopButton from '../../images/stop_button.svg';
import cancelBtn from '../../images/times_btn.png';
import rightArrow from '../../images/right_arrow.svg';
import backArrow from '../../images/back_arrow.svg';
import recordMic from '../../images/record_mic.svg'

import MicRecorder from 'mic-recorder-to-mp3';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function SampleNextArrow(props) {
    const { onClick } = props;
    return (
        <div className={styles.arrow_container_right} onClick={onClick}>
           <img src={rightArrow} alt=""/>
        </div>
    );
}
  
function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
        <div className={styles.arrow_container_left} onClick={onClick}>
           <img src={backArrow} alt=""/>
        </div>
    );
}

const recordContent = [
    {
        id:1,
        content:"English, although the official language of Nigeria, was a formal language with which strangers and non-relatives addressed you. It had the potency of digging craters between you and your friends or relatives if one of you switched to using it"
    },
    {
        id:2,
        content:"English, although the official language of Nigeria, was a formal language with which strangers and non-relatives addressed you. It had the potency of digging craters between you and your friends or relatives if one of you switched to using it"
    },
    {
        id:3,
        content:"English, although the official language of Nigeria, was a formal language with which strangers and non-relatives addressed you. It had the potency of digging craters between you and your friends or relatives if one of you switched to using it"
    }
]


const RecordingModal = (props) => {
    const settings = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
      
    };

    const[closeModal, setCloseModal] = useState(true)

    const[isRecording, setIsRecording] = useState(false);
    const[blobURL, setBlobURL] = useState('');
    const[isBlocked, setIsBlocked] = useState(false);

    const start = () => {
        if (isBlocked) {
          console.log('Permission Denied');
        } else {
          Mp3Recorder
            .start()
            .then(() => {
              setIsRecording(true);
            }).catch((e) => console.error(e));
        }
      };

    const stop = () => {
    Mp3Recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)

          setBlobURL(blobURL)
          setIsRecording(false);
        }).catch((e) => console.log(e));

    };
  
    useEffect(() => {
        navigator.getUserMedia({ audio: true },
            () => {
            console.log('Permission Granted');
            setIsBlocked(false);
            },
            () => {
            console.log('Permission Denied');
            setIsBlocked(true);
            },
        );
    });

    return(
        closeModal ?
        (<div className={styles.recording_modal_fluid}>
            <div className={styles.modal_container}>
                <div className={styles.modal_content}>
                    <div className={styles.cancel_btn}>
                        <img src={cancelBtn} alt="" onClick={() =>{ setCloseModal(false);
                        props.setOpenModal(false)}}/>
                    </div>
                    <div className={styles.content_slide_container}>
                        <div className={styles.content_slide_item}>
                            <small>Read aloud</small>
                            <p>{props.content}</p>
                        </div>        
                    </div>
                    <div className={styles.button_container}>
                        <img src={recordMic} alt="" onClick={start} hidden={isRecording}/>
                        <img src={stopButton} alt="stop button" onClick={stop} hidden={!isRecording}/>
                        <p hidden={!isRecording}>Recording...</p>
                    </div>
                    <div className={styles.audio_container}>
                        <audio src={blobURL} controls="controls" />
                    </div>
                </div>
            </div>
        </div>): null
    )
}

export default RecordingModal;
import React from 'react';
import Layout from '../components/Layout';
import PasswordRecoveryForm from '../components/PasswordRecoveryForm/PasswordRecoveryForm';

const PasswordRecoveryPage = () => {
    return(
        <Layout>
            <PasswordRecoveryForm />
        </Layout>
    )
}

export default PasswordRecoveryPage;
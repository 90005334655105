import React from 'react';
import styles from './contributor-item.module.scss'

const ContributorItem = ({img}) => {

    return(
        <div className={styles.contributor_image}>
            <img src={img} alt="contributor"/>
        </div>
    )
}

export default ContributorItem
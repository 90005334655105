import React from 'react';
import styles from './audio-recording-sub-hero.module.scss';
import personRecording from '../../images/person_recording.svg'

const AudioRecordingSubHero = () => {
    return (
        <div className={styles._}>
            <div className={styles.container}>
                <div className={styles.sub_hero_text}>
                    <h1>Have fun with these word blocks</h1>
                </div>
                <div className={styles.sub_image}>
                    <img src={personRecording} alt="person recording"/>
                </div>
            </div>
        </div>
    )
}

export default AudioRecordingSubHero;
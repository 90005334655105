import React, { useState } from 'react';
import mic from '../../images/mic.png';
import { Link } from 'react-router-dom';
import styles from './homehero.module.scss';
import RecordingModal from '../RecordingModal/RecordingModal';

const HomeHero = () => {
    const[openModal, setOpenModal] = useState(false);

    return(
        <div className={styles.home_hero_container_fluid}>
            <div className={styles.container}>
                <div className={styles.mic_image}>
                   <Link  to="/word-blocks"><img src={mic} alt="Tap to speak"/></Link>
                   {/* <img src={mic} alt="Tap to speak" onClick = {() => setOpenModal(true)}/> */}
                </div>
            </div>
           { 
               openModal ? <RecordingModal setOpenModal={setOpenModal} openModal={openModal}/> : null
           }
        </div>
    )
}

export default HomeHero

import React from 'react';
import Layout from '../components/Layout';
import PrivacyPolicyHero from '../components/PrivacyPolicyHero/PrivacyPolicyHero';
import PrivacyPolicySection from '../components/PrivacyPolicySection/PrivacyPolicySection';

const PrivacyPolicyPage = () => {
    return(
        <Layout>
           <PrivacyPolicyHero />
           <PrivacyPolicySection />
        </Layout>
    )
}



export default PrivacyPolicyPage;
import React from 'react';

import styles from './faq-hero.module.scss';

const FaqHero = () => {

    return(
        <div className={styles._}>
            <div className={styles.container}>
               <p>Frequently Asked Questions (FAQs)</p>
               <div className={styles.search_input}>
                    <input type="text" placeholder="Search for questions"/>
               </div>
            </div>
        </div>
    )
}

export default FaqHero
import React from 'react';

import styles from './privacy-policy-hero.module.scss';

const PrivacyPolicyHero = () => {

    return(
        <div className={styles._}>
            <div className={styles.container}>
               <p>Privacy Policy</p>
            </div>
        </div>
    )
}

export default PrivacyPolicyHero
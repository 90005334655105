import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from '../../redux/actions/authActions';
import styles from './login-form.module.scss';
import ReactLoading from 'react-loading';



const LoginForm = (props) => {
    LoginForm.propTypes = {
        login: PropTypes.func.isRequired,
        isAuthenticated:PropTypes.bool,
        isLoading:PropTypes.bool
    }

    if(props.isAuthenticated){
        return <Redirect to="/dashboard" />
    }
    return(
        <div className={styles.login_form_container_fluid}>
            <div className={styles.container}>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <h1>Welcome Back!</h1>
                        <Link to="/signup">Don’t have an account?</Link>
                    </div>
                    <div className={styles.login_form}>
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={Yup.object({
        
                            email: Yup.string()
                            .email('Invalid email address')
                            .required('Email address is required'),

                            password: Yup.string()
                            .required("Password is required"),
        
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            props.login(values.email, values.password);
                            // alert(JSON.stringify(values.email));
                        }}
                        > 
                            <Form>
                                <div className={styles.input_item}>
                                    <label>Email Address</label> <br />
                                    <Field type="email" name="email" placeholder="" className={styles.input} />
                                    <ErrorMessage name="email" className={styles.error_message} component="div" />
                                </div>
                                <div className={styles.input_item}>
                                    <label>Password</label> <br />
                                    <Field type="password" name="password" placeholder="" className={styles.input} />
                                    <ErrorMessage name="password" className={styles.error_message} component="div" />
                                </div>
                                <div className={styles.forget_password}>
                                    <Link to='/password-recovery'>Forgot Password</Link>
                                </div>
                                {
                                    props.isLoading ? ( 
                                    <div className={styles.button_container}>
                                        <button className={styles.loading_button} type="submit" disabled> <p>Submit</p>  <ReactLoading className={styles.loading_icon} type={'spin'} color={'#FFFFFF'} height={'20%'} width={'20%'} /></button>
                                    </div>
                                     ) : 
                                    (<div className={styles.button_container}>
                                        <button type="submit">Submit</button>
                                     </div>)
                                    
                                }
                                
                            </Form>
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps, { login })(LoginForm);
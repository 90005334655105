import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Fade } from 'react-reveal';
import styles from './newsletter-form.module.scss';
import { connect } from "react-redux";
import { newsletterSubscription } from '../../redux/actions/authActions';
import ReactLoading from 'react-loading';

const NewsletterForm = (props) => {
    return(
        <div className={styles.newsletter_container_fluid}>
            <div className={styles.container}>
                <Fade>
                    <div className={styles.heading}>
                        <p>Subscribe to our mailing list to receive updates on this project.</p> 
                    </div>
                    <div className={styles.form_container}>
                        <Formik
                            initialValues = {{ email:'' }}
                            validationSchema = {Yup.object({
                                email: Yup.string()
                                .email('Invalid email address')
                                .required('Email address is required'),
                            })}
                            onSubmit={(values, { setSubmitting }) => {
                                props.newsletterSubscription(values.email);
                            }}
                            >
                                <Form>
                                    <div className={styles.email_input}>
                                        <Field type="email" name="email" placeholder="Enter your email address" className={styles.input} />
                                        <ErrorMessage name="email" className={styles.error_message} component="div" />
                                    </div>
                                    {
                                        props.isLoading ? (
                                            <div className={styles.button_container}>
                                                <button className={styles.loading_button} type="submit" disabled> <p>Submit</p>  <ReactLoading className={styles.loading_icon} type={'spin'} color={'#FFFFFF'} height={'20%'} width={'20%'} /></button>
                                            </div>
                                        ) : ( 
                                            <div className={styles.button_container}>
                                                <button type="submit">Subscribe</button>
                                            </div>
                                            )
                                    }
                                </Form>
                        </Formik>
                    </div>
                </Fade>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading
})

export default connect(mapStateToProps, { newsletterSubscription })(NewsletterForm);
